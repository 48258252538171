#this Navigational Header will apply to all the pages on the site

.nav-header {
    height: 2cm;
    background-color: #0066cc;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 2rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.nav-links {
    position: absolute;
    top: 1.2cm;  /* Adjust for vertical positioning */
    right: 8cm;  /* Moves it to the right */
    display: flex;
    gap: 2cm;
}

.nav-link {
    color: white;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
}

.nav-link:hover {
    text-decoration: underline;
}
