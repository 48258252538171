.header {
    width: 100%;
    height: 100vh;
    background: url('../assets/images/globe-bgd-v01.jpg') repeat-y center center/cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns everything inside to the left */
    padding-left: 20px; /* Adjust if needed */
    justify-content: space-between;
    text-align: center;
    color: white;
    position: relative;
    padding-top: 7cm; /* Add padding to account for fixed nav */
}

.header-top {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 100px;
    position: absolute;
    top: 0;
    left: 0;
}

.logo {
    height: 60px;
    margin-left: 30px;
}

.slogan {
    margin-left: 15px;
    font-size: 1.5rem;
    font-weight: bold;
}

.header-content {
    margin-top: auto;
    padding-bottom: 50px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    color: black; /* Ensures text is black */
    transform: translateY(-5cm); /* Moves content up by 5cm */
    width: 80%; /* Adjust width as needed */
    max-width: 800px; /* Ensures it doesn't stretch too wide */
}

.header-content h1,
.header-content p {
    text-align: left;
    margin-left: 10px; /* Adjust spacing from the left */
}

.main-logo {
    margin-top: 20px;
    width: 150px;
    height: auto;
}

.bottom-button {
    position: absolute;
    bottom: 20px;
    left: 50px; /* Moves the button to the left */
    background-color: #dda1a1;
    color: rgb(83, 34, 34);
    border: none;
    padding: 15px 40px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.bottom-button:hover {
    background-color: #d65151;
    transform: scale(1.05);
}